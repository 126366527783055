import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Paper,
    TextField,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Modal,
    Card,
    CardHeader,
    IconButton,
    CardContent,
    Divider,
    Box,
    Pagination,
    CircularProgress,
    Autocomplete,
    TableSortLabel,
} from '@mui/material';
import CustomDatePicker from '../common/customdatepicker';
import dayjs from 'dayjs';
import apiService from '../../services/actions';
import uploadIcon from "../../assets/images/uploadIcon.svg";
import uploadIcon2 from "../../assets/images/uploadIcon2.svg";
import eyeIcon from "../../assets/images/eyeIcon.svg";
import downloadIcon from "../../assets/images/downloadIcon.svg";
import deleteIcon from "../../assets/images/deleteIcon.svg";
import CloseIcon from '@mui/icons-material/Close';
import CustomDateTimePicker from '../common/customdatetimepicker';
import { formatDateToMMDDYYYY, formatToCurrentDateTime, formateDateToDDMMYYYYHHMM } from '../common';
import { toast } from 'react-toastify';
import moment from 'moment';
import PatientSearch from '../common/patientSearch';
import { useLocation, useNavigate } from 'react-router-dom';
const CompletedOrders = () => {
    const fileInputRef = useRef(null);
    const inputRef = useRef(null);

    const location = useLocation();
    const receivedFilter = location.state;

    const [hospitalId, setHospitalId] = useState('');
    const [locationId, setLocationId] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
    const [uploadReportsModalOpen, setUploadReportsModalOpen] = useState(false);
    const [orderDetails, setOrderDetails] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedLabReport, setSelectedLabReport] = useState(null);
    const [typedLabReport, setTypedLabReport] = useState('');
    const [loadinModal, setModalLoading] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [viewModalData, setViewModalData] = useState(null);
    const [sampleCollectionModal, setSampleCollectionModal] = useState(false);
    const [viewLabTests, setViewLabTests] = useState(false);
    const [labTestDetails, setLabTestDetails] = useState([]);
    const [sampleSelectionRecord, setSampleCollectionRecord] = useState([]);
    const [page, setPage] = useState(1);
    const itemsPerPage = 10;
    const [editProcedureStatus, setEditProcedureStatus] = useState(null);
    const [editReportsUpload, setEditReportsUpload] = useState(null);
    const [labTestsOrderReports, setLabTestsOrderReports] = useState([])
    const [patientLabTestsOrderDetails, setPatientLabTestsOrderDetails] = useState([])
    const [selectedFiles, setSelectedFiles] = useState([]);
    const loginObjStr = localStorage.getItem('loginObj');
    const [patientSearch, setpatientSearch] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [labList, setLablist] = useState([]);

    const [formData, setFormData] = useState(
        {
            homeSampleCollectionDateTime: '',
            labTestDateTime: '',
            techniciansAssigned: '',
            contactNumber: '',
            status: '',
        }
    );
    const [sort, setSort] = useState({
        sortBy: 'orderId',
        sortOrder: 'asc',
    });
    const loginObj = JSON.parse(loginObjStr);
    const userCode = loginObj?.userSession?.userCode || null;
    const filePath = `${process.env.REACT_APP_IMAGE_URL}`;
    let currentOrder = null;
    const isPDF = viewModalData?.photoName ? viewModalData?.photoName?.toLowerCase().endsWith('.pdf')
        : viewModalData?.reportInvoiceDocName ? viewModalData?.reportInvoiceDocName?.toLowerCase().endsWith('.pdf') : false;

    const parseLocationData = (locationData) => {
        try {
            const data = JSON.parse(locationData);
            const { hospitalId, locationId } = data;
            setHospitalId(hospitalId);
            setLocationId(locationId);
        } catch (error) {
            console.error('Error parsing location data:', error);
        }
    };

    useEffect(() => {
        const locationData = localStorage.location;
        if (locationData && localStorage?.userRole !== 'DIAGGROUPADMIN') {
            parseLocationData(locationData);
        } else {
            console.log('No data found in localStorage.location or the data is empty.');
        }
    }, []);

    const togglePSEditMode = (orderId) => {
        setEditProcedureStatus((prevMode) => (prevMode === orderId ? null : orderId));
    };

    const toggleRUEditMode = (orderId) => {
        setEditReportsUpload((prevMode) => (prevMode === orderId ? null : orderId));
    };

    const getCollectionStatus = (i) => {
        const statusMap = {
            1: 'Order Confirmed',
            // 2: 'Test@Lab',
            3: 'Technician Assigned',
            4: 'Sample Collected',
            5: 'Cancelled by Patient',
            6: 'Cancelled by Diagnostic',
        };

        return statusMap[i] || '';
    }
    const handleClosepatientSearch = (e) => {
        console.log(e)
        setSelectedPatient(e)
        setpatientSearch(false)
        handleSearch(e)
    }
    const handleUploadClick = async (order) => {
        setSelectedOrder(order);
        setLoading(true);

        const labTestsOrderPayload = {
            orderId: order?.orderId,
            status: 1,
            docmentRequired: 'Y'
        };
        const patientLabTestsOrderPayload = {
            patientLabTestOrderId: order?.orderId
        };

        try {
            const labTestsOrderReportResponse = await apiService.getLabTestsOrderReport(labTestsOrderPayload);

            if (labTestsOrderReportResponse?.status === 200 && labTestsOrderReportResponse?.data) {
                setLabTestsOrderReports(labTestsOrderReportResponse?.data);
            }
        } catch (error) {
            console.error('Error fetching lab tests order report:', error);
        }

        try {
            const patientLabTestsOrderDetailsResponse = await apiService.getPatientLabTestsOrderDetails(patientLabTestsOrderPayload);

            if (patientLabTestsOrderDetailsResponse?.status === 200 && patientLabTestsOrderDetailsResponse?.data) {
                setPatientLabTestsOrderDetails(patientLabTestsOrderDetailsResponse?.data);
            }
        } catch (error) {
            console.error('Error fetching patient lab tests order details:', error);
        }
        setLoading(false)
        setModalOpen(true)
    }

    const handleDownloadClick = async (rec) => {
        if (rec?.photoName && rec.type != 'xlsx' &&  rec.type != 'xls'){
            const response = await fetch(filePath + rec.photoName);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'image.png';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);}
        else if (rec?.photoName && (rec.type == 'xlsx' ||   rec.type == 'xls')){
            window.open(filePath + rec.photoName, '_blank');

            }
        
        else {
            toast.error("Download is not available at the moment, Please try after sometime", {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };

    const handleUploadInVoiceClick = (order) => {
        currentOrder = order;
        fileInputRef.current.click();
    };

    const uploadInvoice = async (e, orderDetails) => {
        if (e?.target?.files && e.target.files.length > 0) {
            setLoading(true)
            const selectedFile = e.target.files[0];
            const base64Strings = await encodeBase64Files(selectedFile);

            if (selectedFile && currentOrder) {
                const updatedOrderDetails = {
                    ...currentOrder,
                    reportInvoiceDoc: base64Strings?.[0],
                    reportInvoiceDocName: currentOrder?.reportInvoiceDocName ? currentOrder?.reportInvoiceDocName : selectedFile.name,
                    reportInvoiceDocType: selectedFile?.type?.split('/')?.[1],
                };

                const uploadInvoiceResponse = await apiService.patientLabTestsOrder(updatedOrderDetails, 'PUT');
                setLoading(false)
                if (uploadInvoiceResponse?.status === 200 && uploadInvoiceResponse?.data) {
                    if (uploadInvoiceResponse.data) {
                        toast.success("Your file Uploaded successfully.", {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        handleSearch();
                    }
                    else {
                        toast.error("Please try after sometime", {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }

            }
            currentOrder = null;
        }
    };


    const handleReceiptClick = async (order) => {
        setSelectedOrder(order);
        setLoading(true);

        const patientLabTestsOrderPayload = {
            patientLabTestOrderId: order?.orderId
        };

        try {
            const patientLabTestsOrderDetailsResponse = await apiService.getPatientLabTestsOrderDetails(patientLabTestsOrderPayload);

            if (patientLabTestsOrderDetailsResponse?.status === 200 && patientLabTestsOrderDetailsResponse?.data) {
                setPatientLabTestsOrderDetails(patientLabTestsOrderDetailsResponse?.data);
            }
        } catch (error) {
            console.error('Error fetching patient lab tests order details:', error);
        }
        setLoading(false);
        setInvoiceModalOpen(true);
    }

    const calculateTotalPrice = () => {
        if (!patientLabTestsOrderDetails) {
            return 0;
        }
        const totalPrice = patientLabTestsOrderDetails.reduce((total, test) => total + Number(test.amount || 0), 0);
        return Number(totalPrice.toFixed(2));
    };


    const calculateTotalDiscount = () => {
        if (!patientLabTestsOrderDetails) {
            return 0;
        }
        const totalDiscount = patientLabTestsOrderDetails.reduce((sum, test) => sum + Number(test.discountAmount || 0), 0);
        return Number(totalDiscount.toFixed(2));
    };

    const calculatePayableAmount = () => {
        const totalPrice = calculateTotalPrice();
        const totalDiscount = calculateTotalDiscount();
        return Math.max(totalPrice - totalDiscount, 0);
    };

    const handleDownload = () => {
        window.print();
    };

    const handleViewClick = (rec) => {
        if (rec?.reportInvoiceDocName)
            window.open(filePath + rec.reportInvoiceDocName, '_blank');
    };
    const handleViewClick2 = (rec) => {
        if (rec?.photoName)
            window.open(filePath + rec.photoName, '_blank');
    };
    
    const [searchFilters, setSearchFilters] = useState({
        orderId: '',
        patientId: '',
        fromDate: dayjs().subtract(7, 'day'),
        toDate: dayjs(),
        procedureStatus: 2,
        uploadStatus: 2,
        labPartner : receivedFilter?.labName ? receivedFilter?.labName  : '',


    });

    const handleSearch = async (e) => {
        setLoading(true);
        let hospitalIdlocalCopy = null;
        let locationIdlocalCopy = null;
        const locationData = localStorage?.location;
        if (locationData && localStorage?.userRole !== 'DIAGGROUPADMIN') {
            try {
                const data = JSON.parse(locationData);
                const { hospitalId, locationId } = data;
                setHospitalId(hospitalId);
                setLocationId(locationId);
                hospitalIdlocalCopy = hospitalId;
                locationIdlocalCopy = locationId;
            } catch (error) {
                console.error('Error parsing location data:', error);
            }
        } else {
            console.log('No data found in localStorage.location or the data is empty.');
        }
        const payload = {
            procedureStatus: searchFilters?.procedureStatus,
            fromDate1: searchFilters?.fromDate ? formatDateToMMDDYYYY(searchFilters?.fromDate) : undefined,
            toDate1: searchFilters?.toDate ? formatDateToMMDDYYYY(searchFilters?.toDate) : undefined,
            orderId: searchFilters?.orderId ? searchFilters.orderId : undefined,
            patientId:e?.code != undefined ? e?.code:searchFilters?.patientId ? searchFilters.patientId : undefined,
            hospitalId: hospitalIdlocalCopy ? hospitalIdlocalCopy : hospitalId ? hospitalId : undefined,
            locationId: locationIdlocalCopy ? locationIdlocalCopy : locationId ? locationId : undefined,
            status: searchFilters.uploadStatus,
            labName:searchFilters?.hospitalName ? searchFilters.hospitalName : undefined,
            labPartner: searchFilters.labPartner ? searchFilters.labPartner : undefined

        };
        if(payload.labPartner){
            payload.status =  undefined
        }
        try {
            const ordersDetailsResponse = await apiService.getOrderDetails(payload);
            setLoading(false);
            if (ordersDetailsResponse.status === 200 && ordersDetailsResponse?.data) {
                setOrderDetails(ordersDetailsResponse.data)
            } else {
                console.error('API error:', ordersDetailsResponse.statusText);
            }
        } catch (error) {
            console.error('API request error:', error);
        }
    };
    const getLabList=async ()=>{
        const res1 = await apiService.getLabList();
        console.log(res1)
        setLablist(res1.data)

    }
    const handleClear = () => {
        let labData ={...labList}
        // setLablist([])
   
        setSearchFilters(prevState => ({ ...prevState,     orderId: '',
            patientId: '',
            fromDate: dayjs().subtract(7, 'day'),
            toDate: dayjs(),
            procedureStatus: 2,
            uploadStatus: 2,
            hospitalName: '', 
             labPartner:''
        }));
            // setLablist(labData);

    if (inputRef.current) {
        inputRef.current.value = '';
    }}
//     useEffect(() => {
     
//         if(searchFilters.hospitalName == ''){
//             // setSearchFilters(prevState => ({ ...prevState,     labPartner: '',
 
//             // }));
// handleSearch()
// }
//     }, [ searchFilters]); 
    useEffect(() => {
        handleSearch();
        getLabList()
    }, [modalOpen]);

    const getDataForPage = useCallback((page) => {
        const startIndex = (page - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const dataForPage = orderDetails.slice(startIndex, endIndex);
        return dataForPage;
    }, [orderDetails, itemsPerPage]);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const getSortedData = () => {
        return getDataForPage(page).sort((a, b) => {
            const aValue = a[sort.sortBy];
            const bValue = b[sort.sortBy];

            if (typeof aValue === 'number' && typeof bValue === 'number') {
                return sort.sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
            } else {
                const compareResult = aValue?.localeCompare(bValue);
                return sort.sortOrder === 'asc' ? compareResult : -compareResult;
            }
        });
    };

    useEffect(() => {
        getDataForPage(page);
    }, [page, getDataForPage,modalOpen]);

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleCloseInvoiceModal = () => {
        setInvoiceModalOpen(false);
    };

    const ProcedureStatusDropdown = ({ currentStatus, orderId, updateOrderStatus }) => {
        const [status, setStatus] = useState(currentStatus);

        const handleChange = async (event) => {
            const newStatus = event.target.value;
            setStatus(newStatus);

            const updatedOrder = orderDetails?.find(order => order.orderId === orderId);

            if (updatedOrder) {
                updatedOrder.procedureStatus = newStatus;

                try {
                    const procedureStatusUpdate = await apiService.patientLabTestsOrder(updatedOrder, 'PUT');
                    if (procedureStatusUpdate?.status === 200 ) {
                        // if (procedureStatusUpdate.data?.[0]) {
                            toast.success("Procedure Status Updated Successfully", {
                                position: toast.POSITION.TOP_CENTER,
                            });
                        // }
                        // else {
                        //     toast.error("Please try after sometime", {
                        //         position: toast.POSITION.TOP_CENTER,
                        //     });
                        // }
                    }
                    updateOrderStatus(orderId, newStatus);
                    handleSearch();
                } catch (error) {
                    console.error("Failed to update order status:", error);
                             toast.error("Please try after sometime", {
                                position: toast.POSITION.TOP_CENTER,
                            });
                }
            }
        };

        return (
            <Select variant="standard" value={status} onChange={handleChange} size="small">
                <MenuItem value={1}>Pending</MenuItem>
                <MenuItem value={2}>Completed</MenuItem>
            </Select>
        );
    };

    const ReportUploadStatusDropdown = ({ currentStatus, orderId, updateOrderStatus }) => {
        const [status, setStatus] = useState(currentStatus);

        const handleChange = async (event) => {
            setLoading(true)
            const newStatus = event.target.value;
            setStatus(newStatus);
            const updatedOrder = orderDetails?.find(order => order.orderId === orderId);

            if (updatedOrder) {
                updatedOrder.status = newStatus;

                try {
                    const uploadStatusResponse = await apiService.patientLabTestsOrder(updatedOrder, 'PUT');
                    if (uploadStatusResponse?.status === 200 && uploadStatusResponse?.data) {
                        if (uploadStatusResponse.data) {
                            toast.success("Upload Status Updated Successfully", {
                                position: toast.POSITION.TOP_CENTER,
                            });
                        }
                        else {
                            toast.error("Please try after sometime", {
                                position: toast.POSITION.TOP_CENTER,
                            });
                        }
                    }
                    updateOrderStatus(orderId, newStatus);
                    setTimeout(() => {
                        handleSearch();
                    }, 1000);
                } catch (error) {
                    console.error("Failed to update report upload status:", error);
                }
                finally {
                    setLoading(false)
                }
            };

        };
        return (
            <Select variant="standard" value={status} onChange={handleChange} size="small">
                <MenuItem value={1}>Pending</MenuItem>
                <MenuItem value={2}>Uploaded</MenuItem>
                <MenuItem value={0}> Cancelled</MenuItem>
            </Select>
        );
    }

    const handleDeleteClick = async (rec) => {
        setLoading(true);
        const currentDateTime = formatToCurrentDateTime(new Date());
        const payload = [{
            createdBy: userCode,
            createdDate: currentDateTime,
            id: rec?.id,
            labOrderDoc: rec?.labOrderDoc ? rec?.labOrderDoc : "",
            labTestCode: rec?.labTestCode,
            labTestName: rec?.labTestName,
            modifiedBy: userCode,
            modifiedDate: currentDateTime,
            orderId: selectedOrder?.orderId,
            status: 0,
            type: rec?.type,
        }];

        try {
            const deleteLabReportResponse = await apiService.uploadLabReports(payload, 'PUT');

            if (deleteLabReportResponse?.status === 200 && deleteLabReportResponse?.data) {
                if (deleteLabReportResponse.data?.[0]) {
                    toast.success("Your file Deleted successfully.", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setLabTestsOrderReports([]);
                    const labTestsOrderPayload = {
                        orderId: selectedOrder?.orderId,
                        status: 1,
                        docmentRequired: 'Y'
                    };

                    try {
                        const labTestsOrderReportResponse = await apiService.getLabTestsOrderReport(labTestsOrderPayload);

                        if (labTestsOrderReportResponse?.status === 200 && labTestsOrderReportResponse?.data) {
                            setLabTestsOrderReports(labTestsOrderReportResponse?.data);
                        }
                    } catch (error) {
                        console.error('Error fetching lab tests order report:', error);
                    }
                }
                else {
                    toast.error("Please try after sometime", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            }
        } catch (error) {
            console.error('Error Deleting the Lab Test Record', error);
        }
        setLoading(false);
    }

    const updateOrderStatus = (orderId, newStatus) => {
        console.log(orderId, newStatus)
        // Make an API call to update the order status here
        // Example:
        // apiService.updateOrderStatus(orderId, newStatus)
        //   .then((response) => {
        //       // Handle success
        //   })
        //   .catch((error) => {
        //       // Handle error
        //   });
    };

    const handleCollectionStatus = (order) => {
        setSampleCollectionModal(true);
        setSampleCollectionRecord(order);
    }

    const handleFieldChange = (fieldName, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value,
        }));
    };

    const onSampleCollectionClose = () => {
        setSampleCollectionModal(false);
        setSampleCollectionRecord([])
        setFormData('');
    };

    const onSampleCollectionSave = async () => {
        setLoading(true);
        try {
            const updatedOrderDetails = {
                ...sampleSelectionRecord,
                procedureStatus: 1,
                technicianAssigned: formData?.techniciansAssigned,
                technicianContactNo: formData?.contactNumber,
                confirmDateTime: formData?.labTestDateTime ? moment(formData?.labTestDateTime)?.format("YYYY-MM-DD HH:mm:ss") : undefined,
                collectionSampleDate: formData?.homeSampleCollectionDateTime ? moment(formData?.homeSampleCollectionDateTime)?.format("YYYY-MM-DD HH:mm:ss") : undefined,
                collectionStatus: formData?.status,
            };
            const confirmOrderResponse = await apiService.patientLabTestsOrder(updatedOrderDetails, 'PUT');
            setLoading(false);

            if (confirmOrderResponse?.status === 200 && confirmOrderResponse?.data) {
                if (confirmOrderResponse.data) {
                    toast.success("Order updated successfully.", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setOrderDetails([]);
                    setSampleCollectionModal(false);
                    setSampleCollectionRecord([])
                    setFormData('');
                    handleSearch();
                } else {
                    toast.error("Please try after sometime", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            }
        } catch (error) {
            setLoading(false);
            console.error("Error confirming order:", error);
        }
    };

    const handleOrderClick = (order) => {
        setViewLabTests(true);
        setLabTestDetails(order);
    };

    const onCloseLabTestsView = () => {
        setViewLabTests(false);
        setLabTestDetails([]);
    }

    const uploadReportsModalClose = () => {
        setSelectedFiles([]);
        setUploadReportsModalOpen(false)
    };

    const handleInputChange = (event, newInputValue) => {
        setTypedLabReport(newInputValue);
    };

    const handleAutocompleteChange = (event, newValue) => {
        setSelectedLabReport(newValue);
    };

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        setSelectedFiles([...selectedFiles, ...newFiles]);
    };

    const handleRemoveFile = (index) => {
        const newFiles = [...selectedFiles];
        newFiles.splice(index, 1);
        setSelectedFiles(newFiles);
    };

    const encodeBase64Files = async (files) => {
        if (!Array.isArray(files)) {
            files = [files];
        }

        const base64Array = await Promise.all(
            files.map((file) => {
                return new Promise((resolve) => {
                    let reader = new FileReader();
                    reader?.readAsDataURL(file);
                    reader.onload = () => {
                        resolve(reader.result.split(",")[1]);
                    };
                });
            })
        );

        return base64Array;
    };

    const handleSave = async (e) => {
        if(selectedFiles.length == 0  ){
            return   toast.error("Please select file", {
                position: toast.POSITION.TOP_CENTER,
            });
        }        const base64Strings = await encodeBase64Files(selectedFiles);
        const currentDateTime = formatToCurrentDateTime(new Date());
        const isTypedValue = selectedLabReport === null;

        const typedLabTestCode = typedLabReport;
        const typedLabTestName = typedLabReport;

        const selectedLabTestReport = patientLabTestsOrderDetails.find((report) => report.labTestName === selectedLabReport);
        const selectedLabTestCode = selectedLabTestReport ? selectedLabTestReport.labTestName : null;
        // const selectedLabTestCode = selectedLabTestReport ? selectedLabTestReport.labTestCode : null;
        const selectedLabTestName = selectedLabTestReport ? selectedLabTestReport.labTestName : null;
        ;
        const payload = selectedFiles.map((file, index) => ({
            createdBy: userCode,
            createdDate: currentDateTime,
            labOrderDoc: base64Strings[index],
            labTestCode: isTypedValue ? typedLabTestCode : selectedLabTestCode,
            labTestName: isTypedValue ? typedLabTestName : selectedLabTestName,
            modifiedBy: userCode,
            modifiedDate: currentDateTime,
            orderId: selectedOrder?.orderId,
            status: 1,
            type: file?.type?.split('/')?.[1],
        }));
        payload.forEach((element)=>{
            element.type = element.type == 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'? 'xlsx' : element.type == 'vnd.ms-excel' ? 'xls' : element.type
        })
        const uploadlabOrderReportResponse = await apiService.uploadLabReports(payload, 'POST');
        setLoading(true)
        if (uploadlabOrderReportResponse.status === 200 && uploadlabOrderReportResponse?.data) {
            toast.success("Your file uploaded successfully.", {
                position: toast.POSITION.TOP_CENTER,
            });
            let data ={
                "patientId": selectedOrder?.patientId,
                "orderId":selectedOrder?.orderId,
                "share_type": "LU",
                "sourcechannel":"ECLINIC",
                "createdBy":userCode,
                "modifiedBy":userCode
            }
            const res = await apiService.uploadLabReportsLog(data);
            console.log(res)

            setLabTestsOrderReports([]);
            const labTestsOrderPayload = {
                orderId: selectedOrder?.orderId,
                status: 1,
                docmentRequired: 'Y'
            };

            try {
                const labTestsOrderReportResponse = await apiService.getLabTestsOrderReport(labTestsOrderPayload);

                if (labTestsOrderReportResponse?.status === 200 && labTestsOrderReportResponse?.data) {
                    setLabTestsOrderReports(labTestsOrderReportResponse?.data);
                }
            } catch (error) {
                console.error('Error fetching lab tests order report:', error);
            }
            setSelectedFiles([]);

        }
        setLoading(false)


        setUploadReportsModalOpen(false);
    };

    const handleSort = (column) => {
        // If the same column is clicked, toggle the sort order
        const newOrder = sort.sortBy === column && sort.sortOrder === 'asc' ? 'desc' : 'asc';
        setSort({
            sortBy: column,
            sortOrder: newOrder,
        });
    };


    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '2rem' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem', marginBottom: '1rem' }}>
                <Typography variant="h6" sx={{ textAlign: 'left', fontWeight: '600' }}>
                    Completed Orders
                </Typography>
                <div style={{ textAlign: 'right' }}>
                    <Button variant="contained" onClick={() => setpatientSearch(true)} sx={{ borderRadius: "1.875rem" }}>+Advanced Filter</Button>
                </div>
            </div>
            <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999, display: loading ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress color="secondary" />
            </div>
            <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <TextField
                            label="Order ID"
                            variant="outlined"
                            fullWidth
                            value={searchFilters.orderId}
                            size='small'
                            onChange={(e) => setSearchFilters({ ...searchFilters, orderId: e.target.value, labPartner:'' })}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label="Patient ID"
                            variant="outlined"
                            fullWidth
                            size='small'
                            value={searchFilters.patientId}
                            onChange={(e) => setSearchFilters({ ...searchFilters, patientId: e.target.value, labPartner:'' })}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <CustomDatePicker
                            label="From Date"
                            value={searchFilters.fromDate}
                            onChange={(date) => setSearchFilters({ ...searchFilters, fromDate: date, labPartner:'' })}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <CustomDatePicker
                            label="To Date"
                            value={searchFilters.toDate}
                            onChange={(date) => setSearchFilters({ ...searchFilters, toDate: date, labPartner:'' })}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel size='small' >Procedure Status</InputLabel>
                            <Select
                                label="Procedure Status"
                                value={searchFilters.procedureStatus}
                                onChange={(e) => setSearchFilters({ ...searchFilters, procedureStatus: e.target.value, labPartner:'' })}
                                size='small'
                            >
                                <MenuItem value={-2}>All</MenuItem>
                                <MenuItem value={1}>Pending</MenuItem>
                                <MenuItem value={2}>Completed</MenuItem>
                                <MenuItem value={4}>Cancelled</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={2}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel size='small' >Upload Status</InputLabel>
                            <Select
                                label="Upload Status"
                                value={searchFilters.uploadStatus}
                                size='small'
                                onChange={(e) => setSearchFilters({ ...searchFilters, uploadStatus: e.target.value, labPartner:'' })}
                            >
                                <MenuItem value={0}>Cancelled</MenuItem>
                                <MenuItem value={1}>Pending</MenuItem>
                                <MenuItem value={2}>Uploaded</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                  {localStorage?.userRole == 'DIAGGROUPADMIN'  && labList.length > 0  &&<Grid item xs={2}>
  <Autocomplete
    options={labList}
    getOptionLabel={(option) => option.name}
    onChange={(event, newValue) => setSearchFilters({ ...searchFilters, hospitalName: newValue?.name || '', labPartner: '' })}
    renderInput={(params) => (
      <TextField
        {...params}
        label="Lab"
        variant="outlined"
        size="small"
        placeholder="Filter labs"
      />
    )}
    value={labList.find((lab) => lab.name === searchFilters.hospitalName) || null}
  />
</Grid>
 }
                    <Button variant="contained" color="primary" onClick={handleSearch} sx={{ borderRadius: '1rem', width: '10rem', marginTop: '1rem', marginLeft: '1rem' }}>
                        Search
                    </Button>
                    <Button variant="contained" color="secondary"                 onClick={handleClear}
 sx={{ borderRadius: '1rem', width: '10rem', marginTop: '1rem', marginLeft: '1rem' }}>
                        Clear
                    </Button>
                </Grid>
            </Paper>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={sort.sortBy === 'orderId'}
                                    direction={sort.sortOrder}
                                    onClick={() => handleSort('orderId')}
                                >
                                    Order ID
                                </TableSortLabel>
                            </TableCell>
                            <TableCell> <TableSortLabel
                                active={sort.sortBy === 'patientName'}
                                direction={sort.sortOrder}
                                onClick={() => handleSort('patientName')}
                            >
                                Patient
                            </TableSortLabel></TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sort.sortBy === 'hospitalName'}
                                    direction={sort.sortOrder}
                                    onClick={() => handleSort('hospitalName')}
                                >
                                    Diagnostic Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sort.sortBy === 'sourceChannel'}
                                    direction={sort.sortOrder}
                                    onClick={() => handleSort('sourceChannel')}
                                >Revenue Source
                                </TableSortLabel></TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sort.sortBy === 'chehospitalName'}
                                    direction={sort.sortOrder}
                                    onClick={() => handleSort('chehospitalName')}
                                >eClinic Name
                                </TableSortLabel></TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sort.sortBy === 'chelocationName'}
                                    direction={sort.sortOrder}
                                    onClick={() => handleSort('chelocationName')}
                                >eClinic Location
                                </TableSortLabel></TableCell>
                            <TableCell>Sample Collection Status</TableCell>
                            <TableCell>Procedure Status</TableCell>
                            <TableCell>Report Upload</TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sort.sortBy === 'referredBy'}
                                    direction={sort.sortOrder}
                                    onClick={() => handleSort('referredBy')}
                                >Referred By
                                </TableSortLabel></TableCell>
                                {/* <TableCell>Lab Partner</TableCell> */}

                                <TableCell>Lab ConfirmID </TableCell>
                                <TableCell>LabConfirmID Status </TableCell>
                            <TableCell>Total Amount(INR)</TableCell>
                            <TableCell>Lab Reports Upload</TableCell>
                            <TableCell>Receipt</TableCell>
                            <TableCell>Upload Invoice</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {getDataForPage(page).map((order) => ( */}
                        {getSortedData().map((order) => (
                            <TableRow key={order.orderId}>
                                <TableCell>
                                    <Button variant="text" color="primary" size="small" onClick={() => handleOrderClick(order)}>
                                        {order.orderId}
                                    </Button>
                                </TableCell>
                                <TableCell>{order.patientName}</TableCell>
                                <TableCell>{order.hospitalName}</TableCell>
                                <TableCell>{order.sourceChannel}</TableCell>
                                <TableCell>{order.chehospitalName}</TableCell>
                                <TableCell>{order.chelocationName}</TableCell>
                                <TableCell>
                                    <Button variant="text" color="primary" size="small" onClick={() => handleCollectionStatus(order)}>
                                        {getCollectionStatus(order.collectionStatus)}
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    {editProcedureStatus === order.orderId ? (
                                        <ProcedureStatusDropdown
                                            currentStatus={order.procedureStatus}
                                            orderId={order.orderId}
                                            updateOrderStatus={updateOrderStatus}
                                        />
                                    ) : (
                                        <span
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => togglePSEditMode(order.orderId)}
                                        >
                                            {order.procedureStatus === 2
                                                ? 'Completed'
                                                : order.procedureStatus === 1
                                                    ? 'Pending'
                                                    : order.procedureStatus === 4
                                                        ? 'Cancelled'
                                                        : order.procedureStatus}
                                        </span>
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editReportsUpload === order.orderId ? (
                                        <ReportUploadStatusDropdown
                                            currentStatus={order.status}
                                            orderId={order.orderId}
                                            updateOrderStatus={updateOrderStatus}
                                        />
                                    ) : (
                                        <span
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => toggleRUEditMode(order.orderId)}
                                        >
                                            {order.status === 2
                                                ? 'Uploaded'
                                                : order.status === 1
                                                    ? 'Pending'
                                                    : order.status === 0 ? 'Cancelled' : order.status}
                                        </span>
                                    )}
                                </TableCell>
                                <TableCell>{order.referredBy}</TableCell>     
                                {/* <TableCell>{order.ccLabPartner}</TableCell> */}
   
                                <TableCell>{order.extLabConfirmId}</TableCell>
                                <TableCell>{order.extLabConfirmIdStatus}</TableCell>
                                <TableCell>{Number(order.paidAmount)?.toFixed(2)}</TableCell>
                                <TableCell>
                                    <Button onClick={() => handleUploadClick(order)}>
                                    <img src={ order.reportAvailable == 0 ? uploadIcon :uploadIcon2}     alt="Upload" />
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Button onClick={() => handleReceiptClick(order)}>
                                        <img src={eyeIcon} alt="View" />
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <div style={{ display: 'flex' }}>
                                        <Button onClick={() => handleUploadInVoiceClick(order)}>
                                            <img src={uploadIcon} alt="Upload" />
                                        </Button>
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={(e) => uploadInvoice(e, order)}
                                        />
                                        {order?.reportInvoiceDocName &&
                                            <Button onClick={() => handleViewClick(order)}>
                                                <img src={eyeIcon} alt="View" />
                                            </Button>
                                        }
                                    </div>
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {orderDetails?.length > 0 && (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                    <Pagination
                        count={Math.ceil(orderDetails.length / itemsPerPage)}
                        page={page}
                        color="secondary"
                        onChange={handlePageChange}
                    />
                </Box>
            )}

            <Modal open={modalOpen} onClose={handleCloseModal}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Card style={{ maxWidth: '160vh', width: '160vh', maxHeight: '80vh', overflowY: 'auto', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', borderRadius: '8px' }}>
                        <CardHeader
                            title={
                                <Typography variant="h6" style={{
                                    fontSize: '18px',
                                    fontWeight: 500,
                                }}>Upload Report</Typography>
                            }
                            action={
                                <IconButton onClick={handleCloseModal}>
                                    <CloseIcon />
                                </IconButton>
                            }
                            style={{
                                backgroundColor: '#f5f5f5',
                                borderBottom: '1px solid #e0e0e0',
                            }}
                        />
                        <Divider />
                        <CardContent>
                            <div style={{ marginBottom: '20px', borderBottom: '1px solid #e0e0e0', paddingBottom: '3rem', display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Patient:
                                        </Typography>
                                        <Typography variant="body1">
                                            {`${patientLabTestsOrderDetails?.[0]?.patientName} / ${patientLabTestsOrderDetails?.[0]?.patientGender} / (${patientLabTestsOrderDetails?.[0]?.patientId})`}
                                            {/* ${patientLabTestsOrderDetails?.[0].patientAge} Years */}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Mobile Number:
                                        </Typography>
                                        <Typography variant="body1">
                                            {patientLabTestsOrderDetails?.[0]?.patientMobile}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Order ID:
                                        </Typography>
                                        <Typography variant="body1">
                                            {selectedOrder?.orderId}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Requested Date & Time:
                                        </Typography>
                                        <Typography variant="body1">
                                            {formateDateToDDMMYYYYHHMM(patientLabTestsOrderDetails?.[0]?.preferDateTime != null ? patientLabTestsOrderDetails[0].preferDateTime : patientLabTestsOrderDetails?.[0]?.collectionSampleDate)}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Confirmed Date & Time:
                                        </Typography>
                                        <Typography variant="body1">
                                            {formateDateToDDMMYYYYHHMM(patientLabTestsOrderDetails?.[0]?.confirmDateTime)}
                                        </Typography>
                                    </div>
                                </div>
                            </div>

                            <div style={{ marginBottom: '20px', borderBottom: '1px solid #e0e0e0', paddingBottom: '3rem', display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Home Sample Collection Address
                                        </Typography>
                                        <Typography variant="body1">
                                            {selectedOrder?.homeSamplePatientAddress}
                                        </Typography>
                                    </div>
                                </div>
                                <Button variant="contained" color="primary" onClick={() => setUploadReportsModalOpen(true)}>
                                    Upload Report
                                </Button>
                            </div>
                            <div>
                                <Typography variant="h6" sx={{ marginBottom: '1rem', fontSize: '16px', fontWeight: 500 }}>Lab Report</Typography>
                                <Grid container>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Lab Tests</TableCell>
                                                    <TableCell>File Type</TableCell>
                                                    <TableCell>View</TableCell>
                                                    <TableCell>Download</TableCell>
                                                    <TableCell>Action</TableCell>
                                                    <TableCell>Uploaded</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {labTestsOrderReports.map((rec) => (
                                                    <TableRow>
                                                        <TableCell>{rec?.labTestName}</TableCell>
                                                        <TableCell>{rec?.type?.toUpperCase()}</TableCell>
                                                        <TableCell>
                                                            <Button onClick={() => handleViewClick2(rec)}>
                                                                <img src={eyeIcon} alt="View" />
                                                            </Button>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button onClick={() => handleDownloadClick(rec)}>
                                                                <img src={downloadIcon} alt="Download" />
                                                            </Button>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button onClick={() => handleDeleteClick(rec)}>
                                                                <img src={deleteIcon} alt="Delete" />
                                                            </Button>
                                                        </TableCell>
                                                        <TableCell>
                                                           {rec.createdByName}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>

                            </div>
                        </CardContent>
                    </Card>
                </div>
            </Modal>

            <Modal open={invoiceModalOpen} onClose={handleCloseInvoiceModal}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Card style={{ maxWidth: '160vh', width: '160vh', maxHeight: '80vh', overflowY: 'auto', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', borderRadius: '8px' }}>
                        <CardHeader
                            title={
                                <Typography variant="h6" style={{
                                    fontSize: '18px',
                                    fontWeight: 500,
                                }}>Invoice</Typography>
                            }
                            action={
                                <IconButton onClick={handleCloseInvoiceModal}>
                                    <CloseIcon />
                                </IconButton>
                            }
                            style={{
                                backgroundColor: '#f5f5f5',
                                borderBottom: '1px solid #e0e0e0',
                            }}
                        />
                        <Divider />
                        <CardContent>
                            <div style={{ marginBottom: '20px', borderBottom: '1px solid #e0e0e0', paddingBottom: '3rem', display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Patient:
                                        </Typography>
                                        <Typography variant="body1">
                                            {`${patientLabTestsOrderDetails?.[0]?.patientName} / ${patientLabTestsOrderDetails?.[0]?.patientGender} / (${patientLabTestsOrderDetails?.[0]?.patientId})`}
                                            {/* ${patientLabTestsOrderDetails?.[0].patientAge} Years */}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Mobile Number:
                                        </Typography>
                                        <Typography variant="body1">
                                            {patientLabTestsOrderDetails?.[0]?.patientMobile}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Order ID:
                                        </Typography>
                                        <Typography variant="body1">
                                            {selectedOrder?.orderId}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Requested Date & Time:
                                        </Typography>
                                        <Typography variant="body1">
                                            {formateDateToDDMMYYYYHHMM(patientLabTestsOrderDetails?.[0]?.preferDateTime != null ? patientLabTestsOrderDetails[0].preferDateTime : patientLabTestsOrderDetails?.[0]?.collectionSampleDate)}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Confirmed Date & Time:
                                        </Typography>
                                        <Typography variant="body1">
                                            {formateDateToDDMMYYYYHHMM(patientLabTestsOrderDetails?.[0]?.confirmDateTime)}
                                        </Typography>
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                <Typography variant="body1" style={{ fontWeight: 'bold' }}>Tests</Typography>
                                <Typography variant="body1" style={{ fontWeight: 'bold' }}>Total Amount (INR)</Typography>
                            </div>

                            <Divider />

                            {patientLabTestsOrderDetails?.map((test, index) => (
                                <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                    <Typography variant="body1">{test?.labTestName}</Typography>
                                    <Typography variant="body1">{test?.amount?.toFixed(2)}</Typography>
                                </div>
                            ))}

                            <Divider />
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
                                <Typography variant="body1" style={{ fontWeight: 'bold' }}>Total (INR)</Typography>
                                <Typography variant="body1" style={{ fontWeight: 'bold' }}>{calculateTotalPrice().toFixed(2)}</Typography>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                <Typography variant="body1" style={{ fontWeight: 'bold' }}>Curebay Discount (INR)</Typography>
                                <Typography variant="body1">- {calculateTotalDiscount().toFixed(2)}</Typography>

                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                <Typography variant="body1" style={{ fontWeight: 'bold' }}>Payable Amount (INR)</Typography>
                                <Typography variant="body1"> {calculatePayableAmount().toFixed(2)}</Typography>
                            </div>
                            <div>
                            </div>
                            <div style={{ marginTop: '20px', textAlign: 'right' }}>
                                <IconButton onClick={handleDownload} style={{ color: '#3D65FF' }}>
                                    <span style={{ cursor: 'pointer', fontSize: '1rem' }}>Download</span>
                                </IconButton>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </Modal>
            <Modal open={uploadReportsModalOpen} onClose={uploadReportsModalClose}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Card style={{ maxWidth: '100vh', width: '100vh', maxHeight: '80vh', overflowY: 'auto', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', borderRadius: '8px' }}>
                        <CardHeader
                            title={
                                <Typography variant="h6" style={{
                                    fontSize: '18px',
                                    fontWeight: 500,
                                }}>Upload Report</Typography>
                            }
                            action={
                                <IconButton onClick={uploadReportsModalClose}>
                                    <CloseIcon />
                                </IconButton>
                            }
                            style={{
                                backgroundColor: '#f5f5f5',
                                borderBottom: '1px solid #e0e0e0',
                            }}
                        />
                        <Divider />
                        <CardContent>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Autocomplete
                                        value={selectedLabReport}
                                        onChange={handleAutocompleteChange}
                                        inputValue={typedLabReport}
                                        onInputChange={handleInputChange}
                                        options={patientLabTestsOrderDetails.map((report) => report.labTestName)}
                                        freeSolo
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select or type Lab Report"
                                                variant="outlined"
                                                size="small"
                                                style={{ width: '30rem', maxWidth: '30rem' }}
                                            />
                                        )}
                                    />
                                    <input type="file" onChange={handleFileChange} multiple />
                                </div>
                            </div>


                            {selectedFiles.length > 0 && (
                                <div style={{ marginTop: '20px' }}>
                                    <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginBottom: '10px' }}>Selected Files:</Typography>
                                    <ul>
                                        {selectedFiles.map((file, index) => (
                                            <li key={index}>
                                                {file.name}
                                                <IconButton onClick={() => handleRemoveFile(index)} style={{ color: 'red' }}>  <CloseIcon /></IconButton>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                <Button variant="contained" color="primary" onClick={() => handleSave(selectedLabReport)} >Save</Button>
                                <Button variant="outlined" color="secondary" style={{ marginLeft: '10px' }} onClick={uploadReportsModalClose}>Cancel</Button>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </Modal>
            <Modal open={viewModal} onClose={() => setViewModal(false)}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Card style={{ maxWidth: '100vh', width: '100vh', maxHeight: '80vh', overflowY: 'auto', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', borderRadius: '8px' }}>
                        <CardHeader
                            title={
                                <Typography variant="h6" style={{
                                    fontSize: '18px',
                                    fontWeight: 500,
                                }}>  {viewModalData?.reportInvoiceDocName ? 'View Invoice' : 'View Report'}</Typography>
                            }
                            action={
                                <IconButton onClick={() => setViewModal(false)}>
                                    <CloseIcon />
                                </IconButton>
                            }
                            style={{
                                backgroundColor: '#f5f5f5',
                                borderBottom: '1px solid #e0e0e0',
                            }}
                        />
                        <Divider />
                        <CardContent>
                            {isPDF ? (
                                <iframe
                                    title="PDF Viewer"
                                    src={`${filePath}${viewModalData?.photoName || viewModalData?.reportInvoiceDocName}`}
                                    width="100%"
                                    height="100%"
                                    style={{ border: 'none' }}
                                ></iframe>
                            ) : (
                                <img
                                    src={`${filePath}${viewModalData?.photoName || viewModalData?.reportInvoiceDocName}`}
                                    alt={'Lab Test'}
                                    style={{ width: '100%', height: 'auto' }}
                                />
                            )}
                        </CardContent>
                    </Card>
                </div>
            </Modal>

            <Modal open={sampleCollectionModal} onClose={onSampleCollectionClose}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Card style={{ maxWidth: '160vh', width: '160vh', maxHeight: '80vh', overflowY: 'auto', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', borderRadius: '8px' }}>
                        <CardHeader
                            title={
                                <Typography variant="h6" style={{
                                    fontSize: '18px',
                                    fontWeight: 500,
                                }}>Sample Collection</Typography>
                            }
                            action={
                                <IconButton onClick={onSampleCollectionClose}>
                                    <CloseIcon />
                                </IconButton>
                            }
                            style={{
                                backgroundColor: '#f5f5f5',
                                borderBottom: '1px solid #e0e0e0',
                            }}
                        />
                        <Divider />
                        <CardContent>
                            <div style={{ marginBottom: '20px', borderBottom: '1px solid #e0e0e0', paddingBottom: '3rem', display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Patient:
                                        </Typography>
                                        <Typography variant="body1">
                                            {`${sampleSelectionRecord?.patientName} / ${sampleSelectionRecord?.patientGender} / (${sampleSelectionRecord?.patientId})`}
                                            {/* ${patientLabTestsOrderDetails?.[0].patientAge} Years */}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Mobile Number:
                                        </Typography>
                                        <Typography variant="body1">
                                            {sampleSelectionRecord?.patientMobile}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Order ID:
                                        </Typography>
                                        <Typography variant="body1">
                                            {sampleSelectionRecord?.orderId}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Requested Date & Time:
                                        </Typography>
                                        <Typography variant="body1">
                                            {formateDateToDDMMYYYYHHMM(sampleSelectionRecord?.preferDateTime != null ? sampleSelectionRecord.preferDateTime : sampleSelectionRecord?.collectionSampleDate)}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Confirmed Date & Time:
                                        </Typography>
                                        <Typography variant="body1">
                                            {formateDateToDDMMYYYYHHMM(sampleSelectionRecord?.confirmDateTime)}
                                        </Typography>
                                    </div>
                                </div>
                            </div>

                            <div style={{ marginBottom: '20px', borderBottom: '1px solid #e0e0e0', paddingBottom: '3rem', display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Home Sample Collection Address
                                        </Typography>
                                        <Typography variant="body1">
                                            {sampleSelectionRecord?.homeSamplePatientAddress}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={3}>
                                        <CustomDateTimePicker
                                            variant="outlined"
                                            fullWidth
                                            label="Home Sample Collection Date & Time"
                                            selectedDateTime={formData?.homeSampleCollectionDateTime}
                                            onChange={(dateTime) =>
                                                handleFieldChange('homeSampleCollectionDateTime', dateTime)
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <CustomDateTimePicker
                                            variant="outlined"
                                            fullWidth
                                            label="Test at Lab Date & Time"
                                            selectedDateTime={formData?.labTestDateTime}
                                            onChange={(dateTime) =>
                                                handleFieldChange('labTestDateTime', dateTime)
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            label="Technicians Assigned"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            value={formData?.techniciansAssigned || ''}
                                            onChange={(e) => handleFieldChange('techniciansAssigned', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            label="Contact Number"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            type="text"
                                            value={formData?.contactNumber || ''}
                                            onChange={(e) => handleFieldChange('contactNumber', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel size="small" >Status</InputLabel>
                                            <Select
                                                label="Status"
                                                size="small"
                                                value={formData?.status !== undefined ? formData?.status : ''}
                                                onChange={(e) => handleFieldChange('status', e.target.value)}
                                            >
                                                <MenuItem value="1">Order Confirmed</MenuItem>
                                                <MenuItem value="3">Technician Assigned</MenuItem>
                                                <MenuItem value="4">Sample Collected</MenuItem>
                                                <MenuItem value="5">Cancelled by Patient</MenuItem>
                                                <MenuItem value="6">Cancelled by Diagnostic</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                        </CardContent>
                        <CardContent>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                <Button variant="contained" color="primary" onClick={onSampleCollectionSave}>Save</Button>
                                <Button variant="outlined" color="secondary" style={{ marginLeft: '10px' }} onClick={onSampleCollectionClose}>Cancel</Button>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </Modal>
            <Modal open={viewLabTests} onClose={onCloseLabTestsView}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Card style={{ maxWidth: '100vh', width: '100vh', maxHeight: '80vh', overflowY: 'auto', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', borderRadius: '8px' }}>
                        <CardHeader
                            title={
                                <Typography variant="h6" style={{
                                    fontSize: '18px',
                                    fontWeight: 500,
                                }}>Ordered Lab Tests</Typography>
                            }
                            action={
                                <IconButton onClick={onCloseLabTestsView}>
                                    <CloseIcon />
                                </IconButton>
                            }
                            style={{
                                backgroundColor: '#f5f5f5',
                                borderBottom: '1px solid #e0e0e0',
                            }}
                        />
                        <Divider />
                        <CardContent>
                            <Typography variant="h6" style={{ fontSize: '16px', fontWeight: 500, marginBottom: '16px' }}>
                                Lab Test Details
                            </Typography>
                            <Divider style={{ margin: '8px 0' }} />
                            {labTestDetails?.patientLabTestsOrderDetailsList?.map((test, index) => (
                                <div key={index}>
                                    <Typography variant="body1">{test?.labTestName}</Typography>
                                    <Divider style={{ margin: '8px 0' }} />
                                </div>
                            ))}
                        </CardContent>
                    </Card>
                </div>
            </Modal>
            <Modal open={patientSearch}
                onClose={handleClosepatientSearch} >
                <PatientSearch onClose={handleClosepatientSearch} />
            </Modal>
        </div>
    );
};

export default CompletedOrders;
